<template>
  <table-view
    ref="tableView"
    url="/quantityNumber/info/trend/index"
    :filterable="false"
    :filter-form="dataForm"
    :exportable="isAuthed('quantity_station_export')"
    export-url="/quantityNumber/info/trend/download"
    export-file-name="基地方量趋势报表"
    :paging="false"
    show-index
    single
    :table-props="{
      'row-class-name': getCellClassName
    }"
  >
    <el-form slot="search" @keyup.enter.native="query" inline>
      <el-form-item label="生产日期">
        <el-date-picker type="date" v-model="dataForm.startDate" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-form-item label="到">
        <el-date-picker type="date" v-model="dataForm.endDate" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-button @click="query" type="primary">查询</el-button>
    </el-form>
    <template slot="append">
      <template v-for="h in headers">
        <el-table-column v-if="h.children" :label="h.label" :key="h.id" header-align="center">
          <tabel-column-template v-for="s in h.children" :key="s.id" :header="s"></tabel-column-template>
        </el-table-column>
        <tabel-column-template v-else :key="h.id" :header="h"></tabel-column-template>
      </template>
    </template>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'
import TabelColumnTemplate from '@/components/templates/table-column-template'

export default {
  name: 'report-base-trend',

  components: { TableView, TabelColumnTemplate },

  data () {
    return {
      headers: [
        { id: 1, prop: 'dispatchDate', label: '日期', minWidth: 120 },
        { id: 4,
          label: '白莲泾基地',
          children: [
            { id: 41, prop: 'bljStatementQuantityNumber', label: '结算泵量', minWidth: 70 },
            { id: 42, prop: 'bljStatementPrice', label: '结算泵费', minWidth: 70 },
            { id: 43, prop: 'bljConfirmQuantityNumber', label: '确认泵量', minWidth: 70 },
            { id: 44, prop: 'bljConfirmPrice', label: '确认泵费', minWidth: 70 }
          ]
        },

        { id: 5,
          label: '水产路基地',
          children: [
            { id: 51, prop: 'sclStatementQuantityNumber', label: '结算泵量', minWidth: 70 },
            { id: 52, prop: 'sclStatementPrice', label: '结算泵费', minWidth: 70 },
            { id: 53, prop: 'sclConfirmQuantityNumber', label: '确认泵量', minWidth: 70 },
            { id: 54, prop: 'sclConfirmPrice', label: '确认泵费', minWidth: 70 }
          ]
        },
        { id: 6,
          label: '黄楼基地',
          children: [
            { id: 61, prop: 'hlStatementQuantityNumber', label: '结算泵量', minWidth: 70 },
            { id: 62, prop: 'hlStatementPrice', label: '结算泵费', minWidth: 70 },
            { id: 63, prop: 'hlConfirmQuantityNumber', label: '确认泵量', minWidth: 70 },
            { id: 64, prop: 'hlConfirmPrice', label: '确认泵费', minWidth: 70 }
          ]
        },
        { id: 7,
          label: '苏昆基地',
          children: [
            { id: 71, prop: 'skStatementQuantityNumber', label: '结算泵量', minWidth: 70 },
            { id: 72, prop: 'skStatementPrice', label: '结算泵费', minWidth: 70 },
            { id: 73, prop: 'skConfirmQuantityNumber', label: '确认泵量', minWidth: 70 },
            { id: 74, prop: 'skConfirmPrice', label: '确认泵费', minWidth: 70 }
          ]
        },
        { id: 8,
          label: '总计',
          children: [
            { id: 81, prop: 'totalStatementQuantityNumber', label: '结算总量', minWidth: 70 },
            { id: 82, prop: 'totalStatementPrice', label: '结算总费', minWidth: 70 },
            { id: 83, prop: 'totalConfirmQuantityNumber', label: '确认总量', minWidth: 70 },
            { id: 84, prop: 'totalConfirmPrice', label: '确认总费', minWidth: 70 }
          ]
        }
      ],
      dataForm: {
        startDate: '',
        endDate: '',
        mixingTable: [],
        difference: true
      },
      mixingTableList: []
    }
  },

  methods: {
    query () {
      this.$refs.tableView.queryData()
    },

    getCellClassName ({ row, column, rowIndex, columnIndex }) {
      return ''
    }
  },
  created () {
    let current = new Date()
    let year = current.getFullYear() - 1
    let month = current.getMonth() > 9 ? current.getMonth() : '0' + current.getMonth()
    let date = '01'
    this.dataForm.startDate = year + '-' + month + '-' + date
  }
}
</script>
